import React, { useState, useRef, useReducer, useEffect } from 'react';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router';

import { Anchor } from '@ets-global/b2c-website-ui';

import { Who, Partner, You, Tests } from '../NavItems';
import { paths } from '../../paths';
import NavItem from './NavItem';
import useRouter from '../../hooks/useRouter';

import './styles.scss';

function reducer(state, action) {
  switch (action.type) {
    case 'maxSize':
      return {
        maxWidth: '100%',
        overflowing: false,
        expended: false,
      };
    case 'reducedSize':
      return {
        ...state,
        maxWidth: `${action.width}px`,
        overflowing: true,
      };
    case 'toggleExpended':
      return {
        ...state,
        expended: !state.expended,
      };
    default:
      throw new Error('Please use a supported action: maxSize, reducedSize or toggleExpended');
  }
}

const Nav = ({ layout, onToggleNav = () => {} }) => {
  const location = useLocation();
  const { currentRoute } = useRouter();

  const menuRef = useRef();
  const ctaRef = useRef();
  const init = useRef(false);
  const tabHover = useRef({});

  const [active, setActive] = useState(undefined);
  const [state, dispatch] = useReducer(reducer, {
    maxWidth: '100%',
    overflowing: false,
    expended: false,
  });

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', updateDimensions);
      window.addEventListener('orientationchange', updateDimensions);
      if (!init.current && getMenuMaxWidth() > 0) {
        init.current = false;
        updateDimensions();
      }

      return () => {
        window.removeEventListener('resize', updateDimensions);
        window.removeEventListener('orientationchange', updateDimensions);
      };
    }
  });

  const handleClick = (item) => {
    resetActiveClass();
    if (active === undefined || active !== item) {
      onToggleNav(true);
      setActive(item);
    }
  };

  const resetActiveClass = () => {
    onToggleNav(false);
    setActive(undefined);
  };

  const handleMouseChange = (tab, status) => {
    tabHover.current[tab] = status;
    onToggleNav(Object.values(tabHover.current).reduce((acc, tabStatus) => acc || tabStatus, false));
  };

  const getMenuMaxWidth = () => {
    const mainLogo = document.querySelector('.header__logo');
    const logoWidth = mainLogo.parentNode.getBoundingClientRect().left + mainLogo.parentNode.offsetWidth;
    const ctaWidth = ctaRef.current.offsetWidth;
    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    return viewportWidth - logoWidth - ctaWidth - 120;
  };

  const updateDimensions = () => {
    if (menuRef.current) {
      const availableSpace = getMenuMaxWidth();
      let width = 0;
      for (const item of menuRef.current.children) {
        if (width + item.offsetWidth <= availableSpace) {
          width += item.offsetWidth;
        } else {
          break;
        }
      }

      if (menuRef.current.scrollWidth > availableSpace && state.maxWidth !== `${width}px`) {
        dispatch({ type: 'reducedSize', width });
      } else if (menuRef.current.scrollWidth <= availableSpace && state.maxWidth !== '100%') {
        dispatch({ type: 'maxSize' });
      }
    }
  };

  let content = '';
  if (layout) {
    const { whoBlock, partnerBlock, youBlock, testsBlock } = layout;
    content = (
      <>
        <div
          className={classNames('menu__box', { overflowing: state.overflowing })}
          style={{
            maxWidth: state.maxWidth,
          }}
          ref={menuRef}
        >
          {whoBlock && whoBlock.title && (
            <NavItem
              title={whoBlock.title}
              onClick={() => handleClick('who')}
              onMouseChange={(status) => handleMouseChange('who', status)}
              className={classNames({ active: active === 'who' })}
            >
              <Who {...whoBlock} />
            </NavItem>
          )}

          {partnerBlock && partnerBlock.title && (
            <NavItem
              title={partnerBlock.title}
              onClick={() => handleClick('partner')}
              onMouseChange={(status) => handleMouseChange('partner', status)}
              className={classNames({ active: active === 'partner' })}
            >
              <Partner {...partnerBlock} />
            </NavItem>
          )}

          {youBlock && youBlock.title && (
            <NavItem
              title={youBlock.title}
              onClick={() => handleClick('you')}
              onMouseChange={(status) => handleMouseChange('you', status)}
              className={classNames({ active: active === 'you' })}
            >
              <You blocks={youBlock.blocks} testimonial={fromJS(youBlock.testimonial)} />
            </NavItem>
          )}

          {testsBlock && testsBlock.title && (
            <NavItem
              title={testsBlock.title}
              onClick={() => handleClick('test')}
              onMouseChange={(status) => handleMouseChange('test', status)}
              className={classNames({ active: active === 'test' })}
            >
              <Tests blocks={testsBlock.blocks} news={testsBlock.news} />
            </NavItem>
          )}

          {fromJS([
            {
              name: 'footer.bottom.nav.blog',
              link: paths.BLOG,
            },
            {
              name: 'footer.bottom.nav.help_center',
              link: paths.HELP_CENTER.replace(':slug?', ''),
            },
          ]).map((link, key) => (
            <div key={key} className={'menu__item'}>
              <Anchor key={key} to={link.get('link')} className={'menu__link'}>
                <Trans>{link.get('name')}</Trans>
              </Anchor>
            </div>
          ))}
        </div>
        <div className={'menu__item menu__expand'}>
          <button
            className={'menu__expand-burger'}
            onClick={() => {
              dispatch({ type: 'toggleExpended' });
            }}
          >
            <span />
          </button>
        </div>
      </>
    );
  }

  return (
    <nav className={classNames('menu', { expanded: state.expended })}>
      {content}
      <div className={'menu__item menu__ctas'} ref={ctaRef}>
        <Anchor to={paths.PREP_TOOLS} className={'menu__cta menu__cta--light'} data-cypress="prepare-button">
          <Trans>nav.practice.link_nav</Trans>
        </Anchor>
        <Anchor
          to={currentRoute.path === paths.SESSION_SEARCH ? location.pathname : paths.SESSION_SEARCH}
          search={currentRoute.path === paths.SESSION_SEARCH ? location.search : null}
          className={'menu__cta'}
          data-cypress="take-test-button"
        >
          <Trans>nav.pass_test.link_nav</Trans>
        </Anchor>
      </div>
    </nav>
  );
};

export default Nav;
