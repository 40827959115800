import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { Link } from '@ets-global/b2c-website-ui';

import { List } from '../../Element';
import { TestimonialBlock } from '../../EditorialContent';

const You = ({ blocks = [], testimonial }) => {
  const mobile = testimonial
    .get('images')
    .find((image) => image.get('source') === 'MOBILE')
    .toJS();
  const image = testimonial.get('smallImage') ? testimonial.get('smallImage').toJS() : mobile;

  return (
    <div className={'menu__content menu__content-you'}>
      <div className="container">
        <div className="menu__content-left menu__content--double">
          {blocks.map(({ title, link, links }, key) => (
            <div key={key}>
              <Link {...link}>
                <p className={'menu__content-title'}>{title}</p>
              </Link>
              <List
                items={links.map(({ label, ...link }, key) => (
                  <Link key={key} icon={'arrow'} {...link}>
                    {label}
                  </Link>
                ))}
                listClassNames={'menu__content-links'}
              />
            </div>
          ))}
        </div>
        {testimonial && testimonial.size > 0 && (
          <div className={'menu__content-right menu__content-right--no-mobile'}>
            <TestimonialBlock
              title={testimonial.get('quote')}
              author={testimonial.get('author')}
              image={image}
              className="testimony--push"
            />
          </div>
        )}
      </div>
    </div>
  );
};

You.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.object,
      links: PropTypes.array,
    }),
  ),
  testimonial: PropTypes.instanceOf(Map),
};

export default You;
