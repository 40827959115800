import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { renderRoutes } from 'react-router-config';

import { Loader } from '@ets-global/b2c-website-ui';

import { useQuery, useRouter } from '../../hooks';
import Header from '../Header';
import BackToTop from '../Base/BackToTop';
import Footer from '../Footer';
import ErrorPage from '../../pages/Error';
import { LAYOUT } from '../../graphql/queries';

import './styles.scss';

const Layout = ({ route: { routes } }) => {
  const {
    currentRoute: { isExact },
    lang,
    countryEmoIso2,
  } = useRouter();
  const {
    data: { layout },
    loading: loadingLayout,
  } = useQuery(LAYOUT, { variables: { lang, country: countryEmoIso2 } });

  return (
    <Fragment>
      <Header layout={layout} />
      {isExact ? renderRoutes(routes) : <ErrorPage />}
      <BackToTop />
      {loadingLayout ? <Loader /> : <Footer layout={layout} />}
    </Fragment>
  );
};

Layout.propTypes = {
  route: object.isRequired,
};

export default React.memo(Layout, () => true);
