import React from 'react';
import PropTypes from 'prop-types';
import { List as ImmutableList } from 'immutable';

import { Title, List } from '../../components/Element';
import CountryItem from './CountryItem';

const RegionItem = ({ regionName, countries, onCountryChange }) => {
  const items = countries
    .sortBy((country) => country.get('order'))
    .map((country, key) => <CountryItem key={key} country={country} onCountryChange={onCountryChange} />);

  return (
    <div className={'region'}>
      <Title lvl={2} className={'region__name'}>
        {regionName}
      </Title>
      <List items={items} listClassNames={'region__countries'} itemClassNames={'region__country'} />
    </div>
  );
};

RegionItem.propTypes = {
  regionName: PropTypes.string.isRequired,
  countries: PropTypes.instanceOf(ImmutableList).isRequired,
  onCountryChange: PropTypes.func.isRequired,
};

export default RegionItem;
