import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import classNames from 'classnames';

import { Anchor } from '@ets-global/b2c-website-ui';

import Logo from '../Base/Logo/Logo';
import SelectLanguage from './SelectLanguage';
import SocialNetwork from './SocialNetwork';
import User from './User';
import Basket from './Basket';
import Nav from '../Nav';
import { paths } from '../../paths';
import { useRouter } from '../../hooks';

import './styles.scss';

const Header = ({ layout }) => {
  const location = useLocation();
  const { currentRoute } = useRouter();
  const logoIsWhite = currentRoute && currentRoute.logoIsWhite ? currentRoute.logoIsWhite : false;
  const [mobileMenuShowed, setMobileMenuShowed] = useState(false);
  const [subMenuShowed, setSubMenuShowed] = useState(false);
  const [isOnTop, setIsOnTop] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    return () => {
      setMobileMenuShowed(false);
    };
  }, [location]);

  const handleScroll = () => {
    setIsOnTop(window.pageYOffset > 0);
  };

  const toggleMenuMobile = () => {
    setMobileMenuShowed(!mobileMenuShowed);
  };

  let logo = (
    <Logo
      className={classNames('header__logo', {
        'logo--white': logoIsWhite && !isOnTop && !subMenuShowed,
      })}
    />
  );

  if (currentRoute.path === paths.HOME) {
    logo = <h1 className="header__logo-wrapper">{logo}</h1>;
  } else {
    logo = (
      <Anchor className={'header__logo-wrapper'} to={paths.HOME}>
        {logo}
      </Anchor>
    );
  }

  return (
    <header
      className={classNames('header', {
        'header--full': isOnTop || subMenuShowed,
        'header--mobile-menu-open': mobileMenuShowed,
      })}
    >
      {logo}
      <div className={'header__mobile'}>
        <button onClick={toggleMenuMobile} className={'header__mobile-burger'} aria-label="Toggle menu">
          <div>
            <span />
          </div>
        </button>
      </div>
      <div className={'header__content'}>
        <div className={'header__top'}>
          <SelectLanguage />
          <div className={'header__top-links'}>
            <SocialNetwork />
            <span className={'header__top-separator'} />
            <div className={'header__top-links-user'}>
              <User toggleMenuMobile={toggleMenuMobile} />
              <span className={'header__top-separator'} />
              <Basket />
            </div>
          </div>
        </div>
        {layout && (
          <div className={'header__bottom'}>
            <Nav
              layout={layout}
              onToggleNav={(open) => {
                setSubMenuShowed(open);
              }}
            />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
