import React from 'react';
import PropTypes from 'prop-types';

import { DOCUMENT_TYPE } from '../../../constants';
import { Link } from '@ets-global/b2c-website-ui';
import { List } from '../../Element';
import { EditorialContentItem } from '../../EditorialContent';

const Tests = ({ blocks = [], news }) => {
  return (
    <div className="menu__content menu__content-tests">
      <div className="container">
        <div className="menu__content-left  menu__content--double">
          {blocks.map(({ title, description, link, links }, key) => (
            <div key={key}>
              <Link {...link}>
                <p className={'menu__content-title'}>{title}</p>
              </Link>
              {description && (
                <p className="menu__content-text">
                  <strong>{description}</strong>
                </p>
              )}
              {links && (
                <List
                  items={links.map(({ label, ...link }, key) => (
                    <Link key={key} icon={'arrow'} {...link}>
                      {label}
                    </Link>
                  ))}
                  listClassNames={'menu__content-links'}
                />
              )}
            </div>
          ))}
        </div>
        {news && (
          <div className={'menu__content-right menu__content-right--no-mobile'}>
            <EditorialContentItem
              category={DOCUMENT_TYPE.NEWS}
              slug={news.slug}
              title={news.title}
              image={news.images.find((image) => image.source === 'MOBILE')}
              date={news.date}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Tests.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.object,
      links: PropTypes.array,
    }),
  ),
  news: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    ),
    date: PropTypes.string,
  }),
};

export default Tests;
