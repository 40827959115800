import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import classNames from 'classnames';

const NavItem = ({ children, title, onClick, onMouseChange, className }) => {
  const location = useLocation();
  let isOver = useRef(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    isOver = false;
    setActive(false);
    onMouseChange(false);
  }, [location]);

  const handleMouseLeave = () => {
    isOver = false;
    setTimeout(() => {
      if (!isOver) {
        setActive(false);
        onMouseChange(false);
      }
    }, 200);
  };

  const handleMouseEnter = () => {
    setActive(true);
    onMouseChange(true);
  };

  return (
    <div
      className={classNames(className, 'menu__item', { 'menu__item--active': active })}
      onMouseEnter={handleMouseEnter}
      onMouseOver={() => (isOver = true)}
      onFocus={() => (isOver = true)}
      onMouseLeave={handleMouseLeave}
    >
      <button className={'menu__link'} title={title} onClick={onClick}>
        {title}
      </button>
      {children}
    </div>
  );
};

export default React.memo(
  NavItem,
  ({ className: prevClass, title: prevTitle }, { className: nextClass, title: nextTitle }) =>
    prevClass === nextClass && prevTitle === nextTitle,
);
