import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { Link } from '@ets-global/b2c-website-ui';

import { paths } from '../../paths';
import Flag from '../../components/Base/Flag/Flag';

const CountryItem = ({ country, onCountryChange }) => {
  const countryIso2 = country.get('emoIso2').toLowerCase();
  const locale =
    country
      .get('locales')
      .filter((locale) => locale.get('main') === 'yes')
      .first() || country.get('locales').first();

  return (
    <Fragment>
      <Link
        className={'region__country-flag'}
        to={paths.HOME}
        params={{ country: countryIso2, locale: locale.get('code').toLowerCase() }}
        onClick={(e) => {
          e.preventDefault();
          onCountryChange(country);
        }}
      >
        <Flag country={countryIso2} />
      </Link>
      <Link
        className={'region__country-name'}
        to={paths.HOME}
        params={{ country: countryIso2, locale: locale.get('code').toLowerCase() }}
        onClick={(e) => {
          e.preventDefault();
          onCountryChange(country);
        }}
      >
        {country.get('name')}
      </Link>
    </Fragment>
  );
};

CountryItem.propTypes = {
  country: PropTypes.instanceOf(Map).isRequired,
  onCountryChange: PropTypes.func.isRequired,
};

export default CountryItem;
