import { Anchor, Icon, Link } from '@ets-global/b2c-website-ui';
import classNames from 'classnames';
import { List as ImmutableList } from 'immutable';
import { func } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import { useAuth } from '../../../hooks';
import { paths } from '../../../paths';
import links from '../../Account/Menu/links';
import { List } from '../../Element';

const Account = ({ toggleMenuMobile }) => {
  const divEl = useRef(null);
  const [accountActive, setAccountActive] = useState(false);
  const [isLoggingOut, setLoggingOut] = useState(false);
  const { logout } = useAuth();

  const toggleAccount = () => {
    setAccountActive(!accountActive);
  };

  const handleClick = (event) => {
    if (
      divEl?.current &&
      (!divEl.current.contains(event.target) ||
        (event.target.tagName.toLowerCase() === 'a' && divEl.current.contains(event.target))) &&
      accountActive
    ) {
      toggleAccount();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  });

  const doLogout = () => {
    setLoggingOut(true);

    return logout();
  };

  const linkItems = links.map((item, key) => (
    <Anchor key={key} to={item.path} className={'header__tooltip-account-link'} data-cypress={item.dataCypress}>
      <Trans>{item.label}</Trans>
    </Anchor>
  ));

  linkItems.push(
    <Link
      className={'header__tooltip-account-link'}
      data-cypress="header-logout-link"
      onClick={doLogout}
      loading={isLoggingOut}
    >
      <Trans>account.logout.title</Trans>
    </Link>
  );

  const navList = ImmutableList(linkItems);

  return (
    <div className={'header__top-link'} ref={divEl}>
      <Anchor className="header__top-account-link-mobile" to={paths.ACCOUNT_INFORMATION} onClick={toggleMenuMobile}>
        <Icon name={'user'} />
        <Trans>common.my_account.mobile</Trans>
      </Anchor>
      <button className="header__top-account-link-desktop" onClick={toggleAccount} data-cypress="main-account-link">
        <Icon name={'user'} />
        <Trans>common.my_account.desktop</Trans>
      </button>
      <nav
        id={'tooltip-account'}
        className={classNames('header__tooltip-account', {
          active: accountActive,
        })}
      >
        <List
          items={navList}
          listClassNames={'header__tooltip-account-list'}
          itemClassNames={'header__tooltip-account-item'}
        />
      </nav>
    </div>
  );
};

Account.propTypes = {
  toggleMenuMobile: func.isRequired,
};

export default Account;
