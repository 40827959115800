import React, { Fragment } from 'react';
import { List as ImmutableList } from 'immutable';
import PropTypes from 'prop-types';

import { Link } from '@ets-global/b2c-website-ui';

import { List } from '../Element';
import Picture from '../Element/Picture';

const Partner = ({ leftBlock, rightBlock }) => {
  const partners =
    rightBlock && rightBlock.partners
      ? rightBlock.partners.map((partner, key) => <Picture key={key} images={partner.images} />)
      : [];

  return (
    <div className="menu__content menu__content-partners">
      <div className="container">
        <div className="menu__content-left">
          {rightBlock && rightBlock.title && (
            <Fragment>
              <p className="menu__content-title">{rightBlock.title}</p>
              <List items={ImmutableList(partners)} listClassNames={'menu__content-partners-list'} />
              {rightBlock.link && rightBlock.link.label && (
                <Link icon={'arrow'} {...rightBlock.link}>
                  {rightBlock.link.label}
                </Link>
              )}
            </Fragment>
          )}
        </div>
        <div className="menu__content-right">
          {leftBlock && leftBlock.title && (
            <Fragment>
              <p className="menu__content-title">{leftBlock.title}</p>
              <p className="menu__content-text">{leftBlock.description}</p>
              {leftBlock.link && leftBlock.link.label && (
                <Link icon={'arrow'} {...leftBlock.link}>
                  {leftBlock.link.label}
                </Link>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

Partner.propTypes = {
  rightBlock: PropTypes.shape({
    title: PropTypes.string,
    partners: PropTypes.arrayOf(
      PropTypes.shape({
        mobile: PropTypes.shape({
          alt: PropTypes.string,
          url: PropTypes.string,
        }),
        tablet: PropTypes.shape({
          alt: PropTypes.string,
          url: PropTypes.string,
        }),
        desktop: PropTypes.shape({
          alt: PropTypes.string,
          url: PropTypes.string,
        }),
      }),
    ),
    link: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
  leftBlock: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
};

export default Partner;
