import React from 'react';
import { Helmet } from 'react-helmet-async';
import { fromJS } from 'immutable';
import { useTranslation } from 'react-i18next';

import { Loader } from '@ets-global/b2c-website-ui';

import { COUNTRIES } from '../../graphql/queries';
import Content from './Content';
import { useRouter, useQuery } from '../../hooks';

import './styles.scss';

const CountryPage = () => {
  const { lang } = useRouter();
  const { t } = useTranslation();
  const {
    data: { countries },
    loading,
  } = useQuery(COUNTRIES, { lang });

  return (
    <main className={'main'}>
      <Helmet title={t('meta.title.countries')} />
      {loading && <Loader />}
      {!loading && countries && <Content countries={fromJS(countries)} />}
    </main>
  );
};

export default CountryPage;
