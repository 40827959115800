import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isBrowser } from '../../../helpers/ssr';

import './style.scss';

const BackToTop = () => {
  const scrollStepInPx = 50;
  const delayInMs = 16;
  const { t } = useTranslation();
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setIsDisplayed(window.pageYOffset > 100);
  };

  const scrollStep = () => {
    if (window.pageYOffset !== 0) {
      window.scroll(0, window.pageYOffset - scrollStepInPx);
      setTimeout(scrollStep, delayInMs);
    }
  };

  if (isBrowser && isDisplayed) {
    return <button className={'back-to-top'} title={t('general.back-to-top')} onClick={scrollStep} />;
  } else {
    return '';
  }
};

export default BackToTop;
