import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import escaper from '../../../helpers/escaper';

import './styles.scss';

const Autocomplete = ({ label, values, onComplete }) => {
  const [placeholder, setPlaceholder] = useState('');

  const searchAndFormatPlaceholder = (term) => {
    const value = values.filter((value) => value.match(new RegExp(`^${escaper(term)}`, 'gi'))).first();

    return value ? term + value.substring(term.length) : '';
  };

  const predicatePlaceholder = (term) => {
    setPlaceholder(term ? searchAndFormatPlaceholder(term) : '');
  };

  const onChange = (event) => {
    predicatePlaceholder(event.target.value);
    onComplete(event.target.value.trim());
  };

  return (
    <div className="autocomplete-field" data-placeholder={placeholder}>
      <input
        type="text"
        id="autocomplete-field__input"
        className="autocomplete-field__input"
        required
        onChange={onChange}
        placeholder={placeholder}
      />
      <label htmlFor="autocomplete-field__input" className="autocomplete-field__label">
        {label}
      </label>
    </div>
  );
};

Autocomplete.propTypes = {
  onComplete: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(List).isRequired,
  label: PropTypes.string.isRequired,
};

export default Autocomplete;
