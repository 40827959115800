import { fromJS } from 'immutable';

import escaper from './escaper';

export const filterCountry = (countries, term) =>
  countries.filter(
    (country) =>
      country.get('name').match(new RegExp(`^${escaper(term)}`, 'gi')) ||
      (country.get('frenchName') && country.get('frenchName').match(new RegExp(`^${escaper(term)}`, 'gi'))) ||
      (country.get('englishName') && country.get('englishName').match(new RegExp(`^${escaper(term)}`, 'gi')))
  );

export const getCountriesNames = (countries) => {
  countries = countries
    .sortBy((country) => country.get('order'))
    .reduce((acc, country) => {
      acc.push(country.get('name'));
      if (country.get('frenchName')) {
        acc.push(country.get('frenchName'));
      }
      if (country.get('englishName')) {
        acc.push(country.get('englishName'));
      }

      return acc;
    }, []);

  return fromJS(countries);
};

export const mapCountriesByRegion = (countries) =>
  countries
    .sortBy((country) => country.get('region').get('order'))
    .groupBy((country) => country.get('region').get('name'));
