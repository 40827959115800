import React, { Fragment } from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { Link } from '@ets-global/b2c-website-ui';

import { paths } from '../../../paths';

import { DOCUMENT_TYPE } from '../../../constants';

import { EditorialContentItem } from '../../EditorialContent';
import Quote from '../../Base/Quote';
import { List } from '../../Element';

const Who = ({ links, testimonial, news }) => (
  <div className={'menu__content menu__content-who'}>
    <div className={'container'}>
      <div className={'menu__content-left'}>
        {testimonial && (
          <Fragment>
            <Quote className="quote--no-mobile">{testimonial.quote}</Quote>
            <p className={'menu__content-left-no-mobile'}>{testimonial.author}</p>
          </Fragment>
        )}
        {links && (
          <List
            items={fromJS(links).map((link, key) => (
              <Link key={key} icon={'arrow'} {...link.toJS()}>
                {link.get('label')}
              </Link>
            ))}
            listClassNames={'menu__content-links menu__content--double'}
          />
        )}
      </div>

      <div className={'menu__content-right menu__content-right--no-mobile'}>
        {news && (
          <EditorialContentItem
            category={DOCUMENT_TYPE.NEWS}
            slug={news.slug}
            title={news.title}
            image={news.images.find((image) => image.source === 'MOBILE')}
            date={news.date}
          />
        )}
        {news && (
          <Link icon={'arrow'} to={paths.BLOG}>
            <Trans>nav.who_block.blog_link</Trans>
          </Link>
        )}
      </div>
    </div>
  </div>
);

Who.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  newsLink: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string,
  }),
  testimonial: PropTypes.shape({
    quote: PropTypes.string,
    author: PropTypes.string,
  }),
  news: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    ),
    date: PropTypes.string,
  }),
};

export default Who;
