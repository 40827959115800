import React, { useContext } from 'react';
import classNames from 'classnames';

import { Anchor, Icon } from '@ets-global/b2c-website-ui';

import { paths } from '../../paths';
import { PurchaseContext } from '../../store/Purchase';

const Basket = () => {
  const { basketIsEmpty, registrationId } = useContext(PurchaseContext);

  return (
    <div className="header__top-link">
      <Anchor
        className={classNames({ 'header__basket-trigger': !basketIsEmpty })}
        to={paths.PURCHASE_SUMMARY}
        params={{ registrationId }}
        data-cypress={basketIsEmpty ? 'basket-is-empty' : 'basket-is-not-empty'}
      >
        <>
          <Icon name={'basket'} />
          <span>{basketIsEmpty ? `(0)` : 1}</span>
        </>
      </Anchor>
    </div>
  );
};

export default React.memo(Basket);
