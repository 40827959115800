import { useMutation } from '@apollo/client';
import { fromJS, List } from 'immutable';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Autocomplete from '../../components/Base/Autocomplete';
import ConfirmModal from '../../components/Base/Modal/ConfirmModal';
import Separator from '../../components/Base/Separator/Separator';
import { CANCEL_PURCHASE } from '../../graphql/mutations';
import { filterCountry, getCountriesNames, mapCountriesByRegion } from '../../helpers/countrySearch';
import { useRouter } from '../../hooks';
import { paths } from '../../paths';
import { AppContext } from '../../store/App';
import { PurchaseContext } from '../../store/Purchase';
import RegionItem from './RegionItem';

const Content = ({ countries }) => {
  const { country: currentCountry } = useContext(AppContext);

  const { t } = useTranslation();
  const initialCountriesByRegion = mapCountriesByRegion(countries);
  const [countriesByRegion, setCountriesByRegion] = useState(initialCountriesByRegion);
  const countriesNames = getCountriesNames(countries);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const { basketIsEmpty, registrationId, removeRegistrationId } = useContext(PurchaseContext);
  const [selectedCountry, setSelectedCountry] = useState(fromJS(currentCountry));
  const [doRedirect, setDoRedirect] = useState(false);
  const [cancelPurchase] = useMutation(CANCEL_PURCHASE);
  const history = useHistory();
  const { generatePath, countryIso2: routerIso2Country } = useRouter();

  useEffect(() => {
    setCountriesByRegion(mapCountriesByRegion(countries));
  }, [countries]);

  useEffect(() => {
    if (!doRedirect) return;

    if (registrationId) {
      cancelPurchase({
        variables: {
          registrationId,
        },
      }).then(({ data }) => {
        if (data.cancelPurchase) {
          removeRegistrationId();
        }
      });
    }

    const countryIso2 = selectedCountry.get('emoIso2').toLowerCase();
    let locale =
      selectedCountry
        .get('locales')
        .filter((locale) => locale.get('main') === 'yes')
        .first() || selectedCountry.get('locales').first();
    locale = locale.get('code').toLowerCase();
    history.push(generatePath(paths.HOME, { country: countryIso2, locale }));
  }, [doRedirect]);

  const onSearchCountry = (term) => {
    setCountriesByRegion(term ? mapCountriesByRegion(filterCountry(countries, term)) : initialCountriesByRegion);
  };

  return (
    <Fragment>
      <div className={'container'}>
        <Autocomplete onComplete={onSearchCountry} values={countriesNames} label={t('country.search.label')} />
      </div>
      <div className={'container--blue'}>
        <div className={'container'}>
          {countriesByRegion &&
            countriesByRegion
              .map((region, key) => (
                <Fragment key={key}>
                  <RegionItem
                    regionName={key}
                    countries={region}
                    onCountryChange={(country) => {
                      setSelectedCountry(country);
                      if (!basketIsEmpty && country.get('emoIso2').toLowerCase() !== routerIso2Country) {
                        setConfirmModalOpen(true);

                        return;
                      }

                      setDoRedirect(true);
                    }}
                  />
                  {region !== countriesByRegion.last() && <Separator />}
                </Fragment>
              ))
              .toArray()}
        </div>
      </div>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        title={t('country.search.purchase-cancel-confirm-modal.title')}
        onClose={() => setConfirmModalOpen(false)}
        buttons={[
          {
            label: t('common.cancel'),
            className: 'button--transparent-light',
            onClick: () => setConfirmModalOpen(false),
          },
          {
            label: t('country.search.purchase-cancel-confirm-modal.confirm'),
            onClick: () => setDoRedirect(true),
            'data-cypress': 'buying-process.confirm-change-country',
          },
        ]}
      >
        {t('country.search.purchase-cancel-confirm-modal.content')}
      </ConfirmModal>
    </Fragment>
  );
};

Content.propTypes = {
  countries: PropTypes.instanceOf(List).isRequired,
};

export default Content;
