import { Anchor, Icon } from '@ets-global/b2c-website-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router';

import { useAuth, useRouter } from '../../../hooks';
import { paths } from '../../../paths';
import Account from './Account';

const User = ({ toggleMenuMobile }) => {
  const location = useLocation();
  const { currentRoute } = useRouter();
  const { isAuthenticated } = useAuth();
  let referrer;

  if (![paths.LOGIN, paths.REGISTRATION, paths.FORGOTTEN_PASSWORD].includes(currentRoute.path)) {
    referrer = `${location.pathname}${location.search}`;
  }

  if (isAuthenticated) return <Account toggleMenuMobile={toggleMenuMobile} />;

  return (
    <Anchor
      to={paths.LOGIN}
      search={referrer ? `?referrer=${encodeURIComponent(referrer)}` : null}
      className={'header__top-link'}
      data-cypress="main-login-link"
    >
      <>
        <Icon name={'user'} />
        <Trans>common.auth.login</Trans>
      </>
    </Anchor>
  );
};

User.propTypes = {
  isAuthenticated: PropTypes.bool,
  toggleMenuMobile: PropTypes.func.isRequired,
};

export default User;
